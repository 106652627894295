import { initialState, AuthState, authAdapter } from './state';
import { Actions, AuthActionTypes } from './actions';

export function reducer(state = initialState, action: Actions): AuthState {
    switch (action.type) {
        case AuthActionTypes.REGISTER: {
            return {
                ...state,
                registerDetails: action.userDetails,
                isLoading: true,
                error: null,
            };
        }
        case AuthActionTypes.REGISTER_USER: {
            return {
                ...state,
                userDetails: action.userDetails,
                isLoading: true,
                error: null,
            };
        }
        case AuthActionTypes.RESET_REGISTER: {
            return {
                ...state,
                userDetails: null,
                registerDetails: null,
                selectedPlanDetails: null,
                isLoading: false,
                error: null,
            };
        }
        case AuthActionTypes.SELECTED_PLAN: {
            return {
                ...state,
                selectedPlanDetails: action.planDetails,
                isLoading: true,
                error: null,
            };
        }
        default:
            return state;
    }
}
