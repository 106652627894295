import { NgModule } from '@angular/core';

import { DashboardService } from './services/dashboard.service';

import { StoreModule } from '@ngrx/store';
import * as fromDashboard from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { DashboardEffects } from './store/effects';
import { DashboardStoreService } from './dashboard-store.service';


@NgModule({
    imports: [
        StoreModule.forFeature('dashboard', fromDashboard.reducer),
        EffectsModule.forFeature([DashboardEffects]),
    ],
    declarations: [],
    providers: [
        DashboardService,
        DashboardStoreService
    ]
})
export class DashboardStoreModule { }
