import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorage {

  /**
   * Set User info
   * @returns Observable<string>
   */
  public setUserInfo(userInfo) {
    localStorage.setItem(btoa('userInfo'), JSON.stringify(userInfo));
    return this;
  }

  /**
   * Get User info
   * @returns Observable<string>
   */
  public getUserInfo() {
    return localStorage.getItem(btoa('userInfo')) &&
      JSON.parse(localStorage.getItem(btoa('userInfo')));
  }

  /**
   * Get access token
   * @returns Observable<string>
   */
  public getAccessToken(): string {
    const token: string = localStorage.getItem(btoa('accessToken'));
    return token;
  }

  /**
   * Get refresh token
   * @returns Observable<string>
   */
  public getRefreshToken(): Observable<string> {
    const token: string = localStorage.getItem(btoa('refreshToken'));
    // console.log('getRefreshToken::', token);
    return of(token);
  }

  /**
   * Get user roles in JSON string
   * @returns Observable<any>
   */
  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem(btoa('userRoles'));
    try {
      // console.log('roles::', roles);
      return of(JSON.parse(roles));
    } catch (e) { }
  }

  /**
   * Get User Id
   * @returns Observable<string>
   */
  public getUserID(): string {
    const userId: string = localStorage.getItem(btoa('userId'));
    return userId;
  }

  public getHeaderAccessToken() {
    const token: string = localStorage.getItem(btoa('accessToken'));
    return token;
  }

  /**
   * Set access token
   * @returns TokenStorage
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem(btoa('accessToken'), token);
    return this;
  }

  /**
   * Set refresh token
   * @returns TokenStorage
   */
  public setRefreshToken(token: string): TokenStorage {
    localStorage.setItem(btoa('refreshToken'), token);
    return this;
  }


  /**
   * Set userId
   * @returns TokenStorage
   */
  public setUserID(userId: string): TokenStorage {
    localStorage.setItem(btoa('userId'), userId);
    return this;
  }

  /**
   * Remove tokens
   */
  public clear() {
    localStorage.clear();
  }
}
