// Angular
import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
// Lodash
import { assign } from "lodash";
// RxJS
import { Observable, of } from "rxjs";
import { Subscription } from "rxjs";
// Services
import { TokenStorage } from "../../../../../../state/auth/services/token-storage.service";
import { GlobalStoreService } from "../../../../../../state/global/global-store.service";
import { environment } from "../../../../../../../environments/environment";
import { CommonFunction } from "../../../../../../state/shared/common-function/common-function";
import { AuthenticationService } from "../../../../../../state/auth/services/authentication.service";

// Router
import { Router } from "@angular/router";
// Common JWT
import { getLoginUserInfo } from "../../../../../../state/shared/common-function/jwt.helper";

export const user = of({
	userName: "Jhon",
	// pic: './assets/media/users/default.jpg'
});

@Component({
	selector: "kt-user-profile",
	templateUrl: "./user-profile.component.html",
})
export class UserProfileComponent implements OnInit, OnDestroy {
	// Public properties
	user$: Observable<any> = user;

	@Input() avatar = true;
	@Input() greeting = true;
	@Input() badge: boolean;
	@Input() icon: boolean;

	userInfo;
	isAdmin: boolean = false;
	url;

	// subscribe
	subscriptions: Subscription[] = [];

	/**
	 * Component constructor
	 *
	 * @param store: Store<AppState>
	 */
	constructor(public tokenStorage: TokenStorage, private router: Router, public commonFunction: CommonFunction, private globalStoreService: GlobalStoreService, private authenticationService: AuthenticationService, public cd: ChangeDetectorRef) {
		// GET user details.
		this.userInfo = assign({}, getLoginUserInfo());
	}
	/**
	 * On init
	 */
	ngOnInit(): void {}

	/**
	 * Log out
	 */
	logout() {
		localStorage.clear();
		this.router.navigate([""]);
	}

	/**
	 * Change password
	 * @param event
	 */
	reset() {
		localStorage.clear();
		this.router.navigate(["/auth/reset-password"]);
	}

	/**
	 * Function for unscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub && sub.unsubscribe());
	}
}
