import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as globalActions from './actions';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { State } from '../../root-state';
import { GlobalService } from '../services/global.service';

const getEmptyAction = (action: string, reason: string) => Object.assign({ type: `[Global] ${action} ${reason}` });

@Injectable()
export class GlobalEffects {
  constructor(private actions$: Actions, private store: Store<State>, private globalService: GlobalService) { }

  @Effect({ dispatch: false })
  PopupNotificationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.PopupNotificationAction>(globalActions.ActionTypes.TOAST_NOTIFICATION),
    switchMap(action => {
      return of(getEmptyAction('error', 'error occurs'));
    })
  );

  // @Effect()
  // userDetailsEffect$: Observable<Action> = this.actions$.pipe(
  //   ofType<globalActions.UserDetailsAction>(globalActions.ActionTypes.GET_USER_DETAILS),
  //   withLatestFrom(this.store),
  //   switchMap(([action, state]) => {
  //     if (state) {
  //       return this.globalService.getUserDetails()
  //         .pipe(
  //           map((items: any) => new globalActions.UserDetailsSuccessAction(items)),
  //           catchError(error =>
  //             of(new globalActions.FailureAction(error))));
  //     }
  //     return of(getEmptyAction('', 'already loaded'));
  //   }
  //   )
  // );

  @Effect()
  adminUserDetailsEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.AdminUserDetailsAction>(globalActions.ActionTypes.GET_ADMIN_USER_DETAILS),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      if (state) {
        return this.globalService.getAdminUserDetails()
          .pipe(
            map((items: any) => new globalActions.AdminUserDetailsSuccessAction(items)),
            catchError(error =>
              of(new globalActions.FailureAction(error))));
      }
      return of(getEmptyAction('', 'already loaded'));
    }
    )
  );

  @Effect()
  loadCountriesEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.CountriesAction>(globalActions.ActionTypes.LOAD_COUNTRIES),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      if (state) {
        return this.globalService.getAllCountries()
          .pipe(
            map((items: any) => new globalActions.CountriesSuccessAction(items)),
            catchError(error =>
              of(new globalActions.FailureAction(error))));
      }
      return of(getEmptyAction('', 'already loaded'));
    }
    )
  );

  @Effect()
  loadTimezoneEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.TimezoneAction>(globalActions.ActionTypes.LOAD_TIMEZONE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      if (state) {
        return this.globalService.getTimeZone()
          .pipe(
            map((items: any) => new globalActions.TimezoneSuccessAction(items)),
            catchError(error =>
              of(new globalActions.FailureAction(error))));
      }
      return of(getEmptyAction('', 'already loaded'));
    }
    )
  );

  @Effect()
  loadPlanEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.PlanAction>(globalActions.ActionTypes.LOAD_PLAN),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      if (state) {
        return this.globalService.getPlan()
          .pipe(
            map((items: any) => new globalActions.PlanSuccessAction(items)),
            catchError(error =>
              of(new globalActions.FailureAction(error))));
      }
      return of(getEmptyAction('', 'already loaded'));
    }
    )
  );

  @Effect()
  GetCampaignTypeEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.GetCampaignAction>(globalActions.ActionTypes.GET_CAMPAIGN_TYPE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.globalService.getCampaignType()
        .pipe(
          map((items: any) => new globalActions.GetCampaignSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
      return of(getEmptyAction('', 'already loaded'));
    })
  );



  @Effect({ dispatch: false })
  FailureEffect$: Observable<Action> = this.actions$.pipe(
    ofType<globalActions.FailureAction>(globalActions.ActionTypes.FAILURE),
    switchMap(action => {
      return of(getEmptyAction('error', 'error occurs'));
    })
  );
}
