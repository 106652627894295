import { Action } from '@ngrx/store';

export enum ActionTypes {
    // people-list Count Actions
    LOAD_ALL_PEOPLE = '[people-list] Load All People',
    LOAD_ALL_PEOPLE_SUCCESS = '[people-list] Load All People Success',

    ADD_PERSON = '[add-person] Add Person',
    ADD_PERSON_SUCCESS = '[add-person] Add Person Success',

    GET_PERSON_BY_ID = '[people-list] Get People By Id',
    GET_PERSON_BY_ID_SUCCESS = '[people-list] Get People By Id Success',

    UPDATE_PERSON = '[update-person] Update Person',
    UPDATE_PERSON_SUCCESS = '[update-person] Update Person Success',

    DELETE_PERSON = '[delete-person] Delete Person',
    DELETE_PERSON_SUCCESS = '[delete-person] Delete Person Success',

    ADD_CUSTOM_FIELD = '[add-custom-field] Add custom field',
    ADD_CUSTOM_FIELD_SUCCESS = '[add-custom-field] Add custom field success',

    ADD_NEW_NOTE = '[add-new-note] Add new note',
    ADD_NEW_NOTE_SUCCESS = '[add-new-note] Add new note success',

    UPDATE_NOTE = '[update-note] Update note',
    UPDATE_NOTE_SUCCESS = '[update-note] Update note success',

    DELETE_NOTE = '[delete-person] Delete note',
    DELETE_NOTE_SUCCESS = '[delete-person] Delete note Success',

    FAILURE = '[people-list] Failure',
}

export class LoadAllPeopleAction implements Action {
    readonly type = ActionTypes.LOAD_ALL_PEOPLE;
    constructor() { }
}

export class LoadAllPeopleSuccessAction implements Action {
    readonly type = ActionTypes.LOAD_ALL_PEOPLE_SUCCESS;
    constructor(public data: any) { }
}

export class AddPersonAction implements Action {
    readonly type = ActionTypes.ADD_PERSON;
    constructor(public data: any) { }
}

export class AddPersonSuccessAction implements Action {
    readonly type = ActionTypes.ADD_PERSON_SUCCESS;
    constructor(public data: any) { }
}
export class GetPersonByIdAction implements Action {
    readonly type = ActionTypes.GET_PERSON_BY_ID;
    constructor(public personid: number) { }
}

export class GetPersonByIdSuccessAction implements Action {
    readonly type = ActionTypes.GET_PERSON_BY_ID_SUCCESS;
    constructor(public person?: any) { }
}

export class UpdatePersonAction implements Action {
    readonly type = ActionTypes.UPDATE_PERSON;
    constructor(public person: any) { }
}

export class UpdatePersonSuccessAction implements Action {
    readonly type = ActionTypes.UPDATE_PERSON_SUCCESS;
    constructor(public person: any) { }
}

export class DeletePersonAction implements Action {
    readonly type = ActionTypes.DELETE_PERSON;
    // tslint:disable-next-line: variable-name
    constructor(public person_id?: number) { }
}

export class DeletePersonSuccessAction implements Action {
    readonly type = ActionTypes.DELETE_PERSON_SUCCESS;
    constructor(public person: any) { }
}

export class AddCustomFieldAction implements Action {
    readonly type = ActionTypes.ADD_CUSTOM_FIELD;
    constructor(public data: any) { }
}

export class AddCustomFieldSuccessAction implements Action {
    readonly type = ActionTypes.ADD_CUSTOM_FIELD_SUCCESS;
    constructor(public data: any) { }
}

export class AddNewNoteAction implements Action {
    readonly type = ActionTypes.ADD_NEW_NOTE;
    constructor(public data: any) { }
}

export class AddNewNoteSuccessAction implements Action {
    readonly type = ActionTypes.ADD_NEW_NOTE_SUCCESS;
    constructor(public data: any) { }
}

export class UpdateNoteAction implements Action {
    readonly type = ActionTypes.UPDATE_NOTE;
    constructor(public data: any) { }
}

export class UpdateNoteSuccessAction implements Action {
    readonly type = ActionTypes.UPDATE_NOTE_SUCCESS;
    constructor(public data: any) { }
}

export class DeleteNoteAction implements Action {
    readonly type = ActionTypes.DELETE_NOTE;
    // tslint:disable-next-line: variable-name
    constructor(public noteId?: number) { }
}

export class DeleteNoteSuccessAction implements Action {
    readonly type = ActionTypes.DELETE_NOTE_SUCCESS;
    constructor(public note: any) { }
}


export type Actions =
    LoadAllPeopleAction | LoadAllPeopleSuccessAction |
    AddPersonAction | AddPersonSuccessAction |
    GetPersonByIdAction | GetPersonByIdSuccessAction |
    UpdatePersonAction | UpdatePersonSuccessAction |
    DeletePersonAction | DeletePersonSuccessAction |
    AddCustomFieldAction | AddCustomFieldSuccessAction |
    AddNewNoteAction | AddNewNoteSuccessAction |
    DeleteNoteAction | DeleteNoteSuccessAction |
    UpdateNoteAction | UpdateNoteSuccessAction;

