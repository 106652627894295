import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { State } from '../root-state';


@Injectable({ providedIn: 'root' })
export class DashboardStoreService {

    constructor(
        private store: Store<State>
    ) { }

    // Get Campaign count
    selectDashboardCampaignCount(): Observable<any> {
        return this.store.pipe(select(selectors.selectDashboardCampaignCount));
    }

    // Get Campaign Status count
    selectDashboardCampaignGameStatusCountCount(): Observable<any> {
        return this.store.pipe(select(selectors.selectDashboardCampaignGameStatusCount));
    }

    // Dispatch Get All Plan
    dispatchDashboardCampaignCount() {
        this.store.dispatch(new actions.LoadCampaignCountAction());
    }

    // Dispatch Get All Plan
    dispatchDashboardCampaignGameStatusCount() {
        this.store.dispatch(new actions.LoadCampaignGameStatusCountAction());
    }
}
