import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { State } from '../root-state';


@Injectable({ providedIn: 'root' })
export class PeopleListStoreService {

    constructor(
        private store: Store<State>
    ) { }

    selectAllPeople(): Observable<any> {
        return this.store.pipe(select(selectors.selectAllPeople));
    }

    selectGetPersonById(): Observable<any> {
        return this.store.pipe(select(selectors.selectPersonById));
    }

    getCustomField(): Observable<any> {
        return this.store.pipe(select(selectors.selectGetCustomField));
    }

    personIsSuccess(): Observable<boolean> {
        return this.store.pipe(select(selectors.selectIsSuccess));
    }

    // Dispatch Get All People
    dispatchAllPeople() {
        this.store.dispatch(new actions.LoadAllPeopleAction());
    }

    dispatchAddPerson(personid: string) {
        this.store.dispatch(new actions.AddPersonAction(personid));
    }

    dispatchUpdatePerson(personid: string) {
        this.store.dispatch(new actions.UpdatePersonAction(personid));
    }

    dispatchAddNewNote(data: any) {
        this.store.dispatch(new actions.AddNewNoteAction(data));
    }

    dispatchUpdateNote(data: any) {
        this.store.dispatch(new actions.UpdateNoteAction(data));
    }

    dispatchDeleteNote(noteId: any) {
        this.store.dispatch(new actions.DeleteNoteAction(noteId));
    }

    dispatchDeletePerson(personid: any) {
        this.store.dispatch(new actions.DeletePersonAction(personid));
    }

    dispatchGetPersonById(personid: number) {
        this.store.dispatch(new actions.GetPersonByIdAction(personid))
    }

    dispatchAddCustomField(data: any) {
        this.store.dispatch(new actions.AddCustomFieldAction(data));
    }
}
