import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const dashboardAdapter: EntityAdapter<any> = createEntityAdapter<any>({
});

export interface DashboardState extends EntityState<any> {
    isLoading?: boolean;
    error?: any;
    data?: any;
    campaignsCount: any;
    campaignGameStatusCount: any
}

export const initialState: DashboardState = dashboardAdapter.getInitialState(
    {
        isLoading: false,
        error: null,
        data: [],
        campaignsCount: null,
        campaignGameStatusCount: null

    }
);
