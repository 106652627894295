import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';

import { PeopleListState } from './state';
import { state } from '@angular/animations';

export const selectState: MemoizedSelector<
    object,
    PeopleListState
> = createFeatureSelector<PeopleListState>('people-list');

// tslint:disable-next-line: no-shadowed-variable
const getAllPeopleList = (state: PeopleListState): any => state && state.peoples;
export const selectAllPeople: MemoizedSelector<object, any> = createSelector(
    selectState,
    getAllPeopleList
);

// tslint:disable-next-line: no-shadowed-variable
const getCustomField = (state: PeopleListState): any => state && state.fields;
export const selectGetCustomField: MemoizedSelector<object, any> = createSelector(
    selectState,
    getCustomField
);

// tslint:disable-next-line: no-shadowed-variable
const getPersonById = (state: PeopleListState): any => state && state.data;
export const selectPersonById: MemoizedSelector<object, any> = createSelector(
    selectState,
    getPersonById
);

const getIsLoading = (state: PeopleListState): boolean => state.isLoading;
export const selectLoading: MemoizedSelector<object, any> = createSelector(
    selectState,
    getIsLoading
);

const getIsSuccess = (state: PeopleListState): boolean => !state.isLoading && state.error == null;
export const selectIsSuccess: MemoizedSelector<object, any> = createSelector(
    selectState,
    getIsSuccess
);


