import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';

import { AuthState, authAdapter } from './state';

const getError = (state: AuthState): any => state.error;

export const selectState: MemoizedSelector<
    object,
    AuthState
> = createFeatureSelector<AuthState>('register');

const checkRegister = (state: AuthState): any => state.data ? state.data : [];
export const selectRegister: MemoizedSelector<object, any> = createSelector(
    selectState,
    checkRegister
);

const selectRegisterInfo = (state: AuthState): any => state.registerDetails ? state.registerDetails : null;
export const getRegisterInfo: MemoizedSelector<object, any> = createSelector(
    selectState,
    selectRegisterInfo
);

const selectRegisterUserInfo = (state: AuthState): any => state.userDetails ? state.userDetails : null;
export const getRegisterUserInfo: MemoizedSelector<object, any> = createSelector(
    selectState,
    selectRegisterUserInfo
);

const selectPlanInfo = (state: AuthState): any => state.selectedPlanDetails ? state.selectedPlanDetails : null;
export const getPlanInfo: MemoizedSelector<object, any> = createSelector(
    selectState,
    selectPlanInfo
);

