import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    // Local storage method
    REGISTER = '[Register] Submit',
    REGISTER_USER = '[Register User] Submit',
    SELECTED_PLAN = '[Plan] Submit',

    FORGOT_PASSWORD = '[Auth] Forgot Password',
    FORGOT_PASSWORD_SUCCESS = '[Auth] Forgot Password Success',
    LOGOUT = '[Auth] Logout',

    RESET_REGISTER = '[Auth] Reset Register',

    FAILURE = '[Register] Failure',
}

export class RegisterAction implements Action {
    readonly type = AuthActionTypes.REGISTER;
    constructor(public userDetails?: {}) { }
}

export class RegisterUserAction implements Action {
    readonly type = AuthActionTypes.REGISTER_USER;
    constructor(public userDetails?: {}) { }
}

export class SelectedPlanAction implements Action {
    readonly type = AuthActionTypes.SELECTED_PLAN;
    constructor(public planDetails?: {}) { }
}

export class ResetRegister implements Action {
    readonly type = AuthActionTypes.RESET_REGISTER;
}

export class LogOut implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class FailureAction implements Action {
    readonly type = AuthActionTypes.FAILURE;
    constructor(public error: string) { }
}

export type Actions =
    RegisterAction
    | RegisterUserAction
    | ResetRegister
    | LogOut
    | FailureAction
    | SelectedPlanAction;
