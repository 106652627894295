import { NgModule } from '@angular/core';
import { StoreModule, MetaReducer, ActionReducer } from '@ngrx/store';
import * as fromGlobal from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { GlobalEffects } from './store/effects';
import { GlobalService } from './services/global.service';
import { GlobalStoreService } from './global-store.service';
// import { localStorageSync } from 'ngrx-store-localstorage';

// export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
//   return localStorageSync({ keys: ['localNumbers', 'tollfreeNumbers'], rehydrate: true })(reducer);
// }

const metaReducers: Array<MetaReducer<any, any>> = []; // localStorageSyncReducer

@NgModule({
  imports: [
    StoreModule.forFeature('global', fromGlobal.reducer, { metaReducers }),
    EffectsModule.forFeature([GlobalEffects]),
  ],
  declarations: [],
  providers: [
    GlobalService,
    GlobalStoreService
  ]
})
export class GlobalStoreModule { }
