// Angular
import { Injectable, OnDestroy } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse, } from '@angular/common/http';

// RXJS
import { Observable, of, Subscription } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

// SERVICE
import { LoadingBarService } from '@ngx-loading-bar/core';
import { TokenStorage } from '../../../state/auth/services/token-storage.service';
import { ERROR_CODE } from './auth-interceptor.constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from '../../../state/auth/services/authentication.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {

    // subscribe
    subscriptions: Subscription[] = [];
    constructor(
        public tokenStorage: TokenStorage,
        private spinner: NgxSpinnerService,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Loading spinner.
        // this.loadingBarService.setLoading(true);
        this.spinner.show();

        // Get Token.
        const token = this.tokenStorage.getHeaderAccessToken();

        if (token) {
            request = request.clone(this.setAuthorization(request, token));
        }

        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone(this.setContentType(request));
        // }

        return next.handle(request).pipe(
            catchError((error, caught) => {
                this.handleAuthError(error);
                return of(error);
            }) as any,
            finalize(() =>
                this.spinner.hide()
            ) // this.loadingBarService.setLoading(false))
        );
    }

    setContentType(request) {
        return { headers: request.headers.set('Content-Type', 'application/json') };
    }

    setAuthorization(request, token) {
        return { headers: request.headers.set('Authorization', 'Bearer ' + token) };
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        console.log(err);
        switch (err.status) {
            case ERROR_CODE.ERROR_401:
                localStorage.clear();
                this.router.navigate(['']);
                // this.subscriptions.push(
                //     (this.tokenStorage.getIsAdmin() ? this.authenticationService.adminLogout({}) :
                //         this.authenticationService.logout({})).subscribe(logout => {
                //             console.log(logout);
                //         })
                // );
                break;
        }
        // console.log('Error', err);
        throw err;
    }

    /**
     * Function for unscribe all subscriptions
     */
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub && sub.unsubscribe());
    }
}

