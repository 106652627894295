export const ERROR_CODE = {
    SUCCESS_200: 200,
    SUCCESS_400: 200,
    ERROR_400: 400,
    ERROR_401: 401,
    ERROR_403: 403,
    ERROR_404: 404,
    ERROR_405: 405,
    ERROR_409: 409,
    ERROR_413: 413,
    ERROR_500: 500,
    ERROR_504: 504,
    ERROR_422: 422
};


