// Angular
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Component
import { BaseComponent } from '../@metronic/views/themes/demo1/base/base.component';
import { AuthorizationGuardService } from './core/guards/authorization-guard-service';
import { LoginAuthorizationGuardService } from './core/guards/login-authorization-guard-service';

const routes: Routes = [
    {
        path: 'auth',
        canActivate: [LoginAuthorizationGuardService],
        loadChildren: './auth/auth.module#PeopleProfileAuthModule',
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'auth/login'
    },
    {
        path: '',
        children: [
            {
                path: '',
                component: BaseComponent,
                children: [
                    {
                        path: 'people-list',
                        loadChildren: './people-list/people-list.module#PeopleListModule',
                        canActivate: [AuthorizationGuardService],
                    },
                    {
                        path: 'reminders-list',
                        loadChildren: './notification/notification.module#NotificationModule',
                        canActivate: [AuthorizationGuardService],
                    },
                    {
                        path: 'custom-field-list',
                        loadChildren: './custom-field-list/custom-field-list.module#CustomFieldListModule',
                        canActivate: [AuthorizationGuardService],
                    },
                ]
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule]
})
export class UiModulesRouting { }
