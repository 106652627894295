import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const authAdapter: EntityAdapter<any> = createEntityAdapter<any>({
});

export interface AuthState extends EntityState<any> {
    isLoading?: boolean;
    error?: any;
    data?: any;
    registerDetails: any;
    userDetails: any;
    selectedPlanDetails: any;
}

export const initialState: AuthState = authAdapter.getInitialState(
    {
        isLoading: false,
        error: null,
        data: [],
        registerDetails: {},
        userDetails: {},
        selectedPlanDetails: {}
    }
);
