import { NgModule } from '@angular/core';

import { PeopleListService } from './services/people-list.service';

import { StoreModule } from '@ngrx/store';
import * as fromDashboard from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { PeopleListEffects } from './store/effects';
import { PeopleListStoreService } from './people-list-store.service';


@NgModule({
    imports: [
        StoreModule.forFeature('people-list', fromDashboard.reducer),
        EffectsModule.forFeature([PeopleListEffects]),
    ],
    declarations: [],
    providers: [
        PeopleListService,
        PeopleListStoreService
    ]
})
export class PeopleListStoreModule { }
