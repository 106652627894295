// Angular
import { Injectable } from '@angular/core';


export enum MessageType {
  Create,
  Read,
  Update,
  Delete
}

@Injectable()
export class LayoutUtilsService {
/**
 * Service constructor
 *
 * @param snackBar: MatSnackBar
 * @param dialog: MatDialog
 */
  constructor() { }

/**
 * Showing (Mat-Snackbar) Notification
 *
 * @param message: string
 * @param type: MessageType
 * @param duration: number
 * @param showCloseButton: boolean
 * @param showUndoButton: boolean
 * @param undoButtonDuration: number
 * @param verticalPosition: 'top' | 'bottom' = 'top'
 */
  showActionNotification(
    message: string,
    type: MessageType = MessageType.Create,
    duration: number = 10000,
    showCloseButton: boolean = true,
    showUndoButton: boolean = true,
    undoButtonDuration: number = 3000,
    verticalPosition: 'top' | 'bottom' = 'bottom'
  ) {
    const data = {
      message,
      showCloseButton,
      showUndoButton,
      undoButtonDuration,
      verticalPosition,
      type,
      action: 'Undo'
    };
    return null;
  }

/**
 * Showing Confirmation (Mat-Dialog) before Entity Removing
 *
 * @param title: stirng
 * @param description: stirng
 * @param waitDesciption: string
 */
  deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
    return null;
  }

/**
 * Showing Fetching Window(Mat-Dialog)
 *
 * @param data: any
 */
  fetchElements(data) {
    return null;
  }

/**
 * Showing Update Status for Entites Window
 *
 * @param title: string
 * @param statuses: string[]
 * @param messages: string[]
 */
  updateStatusForEntities(title, statuses, messages) {
    return null;
  }
}
