import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as dashboardActions from './actions';
import * as globalActions from '../../global/store/actions';

import { switchMap, map, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';
import { State } from '../../root-state';
import { DashboardService } from '../services/dashboard.service';

import { MessageType } from '../../core/models/message-type.enum';
import { MESSAGE_CONSTANTS } from '../../core/constants/message.constant';
const getEmptyAction = (action: string, reason: string) => Object.assign({ type: `[dashboard] ${action} ${reason}` });

@Injectable()
export class DashboardEffects {
  constructor(private actions$: Actions,
    private store: Store<State>,
    private dashboardService: DashboardService
  ) { }

  @Effect()
  loadDashboardCampaignEffect$: Observable<Action> = this.actions$.pipe(
    ofType<dashboardActions.LoadCampaignCountAction>(dashboardActions.ActionTypes.LOAD_CAMPAIGN_COUNT),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.dashboardService.getCampaignCount()
        .pipe(
          map((items: any) => new dashboardActions.LoadCampaignCountSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
      return of(getEmptyAction('', 'already loaded'));
    })
  );

  @Effect()
  loadDashboardCampaignGameStatusEffect$: Observable<Action> = this.actions$.pipe(
    ofType<dashboardActions.LoadCampaignGameStatusCountAction>(dashboardActions.ActionTypes.LOAD_CAMPAIGN_GAME_COUNT_STATUS),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.dashboardService.getCampaignGameStatusCount()
        .pipe(
          map((items: any) => new dashboardActions.LoadCampaignGameStatusCountSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
      return of(getEmptyAction('', 'already loaded'));
    })
  );
}

