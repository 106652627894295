import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ToastNotification } from '../models/toast-notiifcation.interface';

export const globalAdapter: EntityAdapter<any> = createEntityAdapter<any>({
});

export interface GlobalState extends EntityState<any> {
    isLoading?: boolean;
    toastNotification: ToastNotification;
    data: any;
    plan: any;
    campType: any;
    userDetails: any;
    adminUserDetails: any;
}

export const initialState: GlobalState = globalAdapter.getInitialState(
    {
        toastNotification: null,
        data: null,
        isLoading: false,
        actionType: null,
        error: null,
        plan: null,
        campType: null,
        userDetails: null,
        adminUserDetails: null
    }
);
