import { NgModule } from '@angular/core';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer, RouterStateSerializer } from '@ngrx/router-store';
import { GlobalStoreModule } from './global/global-store.module';
import { AuthenticationService } from '../state/auth/services/authentication.service';
import { BaseService } from '../state/core/services/base.service';
import { AuthNoticeService } from './auth/services/auth-notice.service';
import { TokenStorage } from './auth/services/token-storage.service';
import { AuthStoreModule } from './auth/auth-store.module';
import { DashboardStoreModule } from './dashboard/dashboard-store.module';
import { PeopleListStoreModule } from './people-list/people-list-store.module';

const metaReducers: Array<MetaReducer<any, any>> = [];

@NgModule({
    imports: [
        GlobalStoreModule,
        AuthStoreModule,
        DashboardStoreModule,
        PeopleListStoreModule,
        StoreModule.forRoot({ router: routerReducer }, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' })
    ],
    declarations: [],
    providers: [
        AuthenticationService,
        BaseService,
        AuthNoticeService,
        TokenStorage,
    ]

})
export class StateModule { }
