// Angular
import { NgModule } from '@angular/core';

// Service
import { AuthStoreService } from './auth-store.service';

// RXJS
import { StoreModule, ActionReducer, MetaReducer } from '@ngrx/store';
import * as fromContact from './store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects';

// Storage
import { localStorageSync } from 'ngrx-store-localstorage';

// Local storage
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: [''], rehydrate: true })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
    imports: [
        StoreModule.forFeature('register', fromContact.reducer, { metaReducers }),
        EffectsModule.forFeature([AuthEffects]),
    ],
    declarations: [],
    providers: [
        AuthStoreService
    ]
})
export class AuthStoreModule { }


