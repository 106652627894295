// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { MenuConfigService } from './menu-config.service';
import { MenuConfig } from '../../../_config/demo1/menu.config';

@Injectable()
export class MenuAsideService {
  // Public properties
  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   * Service constructor
   *
   * @param menuConfigService: MenuConfigService
   */
  constructor(public menuConfigService: MenuConfigService) {
    const role = 'user';
    this.loadMenu(role);
  }

  /**
   * Load menu list
   */
  loadMenu(role: string) {
    // get menu list
    let menuItems: any[] = objectPath.get(new MenuConfig().configs, 'aside.items');
    menuItems = menuItems && menuItems.filter(item => item.roles.indexOf(role) > -1);
    this.menuList$.next(menuItems);
  }
}
