import { initialState, GlobalState } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state = initialState, action: Actions): GlobalState {
  switch (action.type) {
    case ActionTypes.TOAST_NOTIFICATION:
      {
        return {
          ...state,
          toastNotification: action.toastNotification,
          isLoading: false
        };
      }
    // case ActionTypes.GET_USER_DETAILS:
    //   {
    //     return {
    //       ...state,
    //       isLoading: false
    //     };
    //   }
    // case ActionTypes.GET_USER_DETAILS_SUCCESS:
    //   {
    //     return {
    //       ...state,
    //       userDetails: action.userDetails,
    //       isLoading: false
    //     };
    //   }
    case ActionTypes.GET_ADMIN_USER_DETAILS:
      {
        return {
          ...state,
          isLoading: false
        };
      }
    case ActionTypes.GET_ADMIN_USER_DETAILS_SUCCESS:
      {
        return {
          ...state,
          adminUserDetails: action.adminUserDetails,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_COUNTRIES:
      {
        return {
          ...state,
          data: action,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_COUNTRIES_SUCCESS:
      {
        return {
          ...state,
          data: action.countries,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_TIMEZONE:
      {
        return {
          ...state,
          data: action,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_TIMEZONE_SUCCESS:
      {
        return {
          ...state,
          data: action.timezone,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_PLAN:
      {
        return {
          ...state,
          data: action,
          isLoading: false
        };
      }
    case ActionTypes.LOAD_PLAN_SUCCESS:
      {
        return {
          ...state,
          plan: action.plan,
          isLoading: false
        };
      }
    case ActionTypes.GET_CAMPAIGN_TYPE:
      {
        return {
          ...state,
          isLoading: true,
        };
      }
    case ActionTypes.GET_CAMPAIGN_TYPE_SUCCESS:
      {
        return {
          ...state,
          campType: action.campaignType,
        };
      }
    default:
      return state;
  }
}
