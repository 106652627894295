import { initialState, DashboardState, dashboardAdapter } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state = initialState, action: Actions): DashboardState {
    switch (action.type) {
        case ActionTypes.LOAD_CAMPAIGN_COUNT: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case ActionTypes.LOAD_CAMPAIGN_COUNT_SUCCESS: {
            return {
                ...state,
                campaignsCount: action.payload,
                error: null,
            };
        }
        case ActionTypes.LOAD_CAMPAIGN_GAME_COUNT_STATUS: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case ActionTypes.LOAD_CAMPAIGN_GAME_COUNT_STATUS_SUCCESS: {
            console.log('call')
            return {
                ...state,
                campaignGameStatusCount: action.payload,
                error: null,
            };
        }
        default:
            return state;
    }
}
