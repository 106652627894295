import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Service
import { BaseService } from '../../core/services/base.service';
import { TokenStorage } from '../../auth/services/token-storage.service';

// Rxjs
import { Observable } from 'rxjs';

// Constant, util, environment.
import { environment } from '../../../../environments/environment';
import { API_PATH } from '../../core/constants/api-constant';
import { CommonUtil } from '../../shared/models/common.util';
import { CommonFunction } from '../../shared/common-function/common-function';

@Injectable()
export class DashboardService extends BaseService {

  constructor(
    protected tokenStorage: TokenStorage,
    protected httpClient: HttpClient,
    protected commonFunction: CommonFunction

  ) {
    super(httpClient, tokenStorage,commonFunction);
  }

  getCampaignCount(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
    API_PATH.DASHBOARD_CAMPAIGN_COUNT;
    return this.get(url);
  }

  getCampaignGameStatusCount(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
    API_PATH.DASHBOARD_CAMPAIGN_STATUS_COUNT;
    return this.get(url);
  }
}
