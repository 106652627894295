import { Action } from '@ngrx/store';
import { ToastNotification } from '../models/toast-notiifcation.interface';
import { Countries } from '../models/countries.interface';


export enum ActionTypes {
  TOAST_NOTIFICATION = '[Global] Toast Notification',

  // COUNTRIES
  LOAD_COUNTRIES = '[Countries] Load',
  LOAD_COUNTRIES_SUCCESS = '[Countries] Load Success',

  // TIME-ZONE
  LOAD_TIMEZONE = '[TimeZone] Load',
  LOAD_TIMEZONE_SUCCESS = '[TimeZone] Load Success',

  // PLAN
  LOAD_PLAN = '[Plan] Load',
  LOAD_PLAN_SUCCESS = '[Plan] Load Success',

  // CAMPAIGN TYPE
  GET_CAMPAIGN_TYPE = '[Campaign] Get Campaign Type',
  GET_CAMPAIGN_TYPE_SUCCESS = '[Campaign] Get Campaign Type Success',

  GET_USER_DETAILS = '[USER] Get User Details',
  GET_USER_DETAILS_SUCCESS = '[USER] Get User Details Success',

  GET_ADMIN_USER_DETAILS = '[USER] Get Admin User Details',
  GET_ADMIN_USER_DETAILS_SUCCESS = '[USER] Get Admin User Details Success',

  // FAILURE
  FAILURE = '[Global] Failure',

}

export class PopupNotificationAction implements Action {
  readonly type = ActionTypes.TOAST_NOTIFICATION;
  constructor(public toastNotification?: any) { }
}

// export class UserDetailsAction implements Action {
//   readonly type = ActionTypes.GET_USER_DETAILS;
//   constructor() { }
// }

// export class UserDetailsSuccessAction implements Action {
//   readonly type = ActionTypes.GET_USER_DETAILS_SUCCESS;
//   constructor(public userDetails?: any) { }
// }

export class AdminUserDetailsAction implements Action {
  readonly type = ActionTypes.GET_ADMIN_USER_DETAILS;
  constructor() {
  }
}

export class AdminUserDetailsSuccessAction implements Action {
  readonly type = ActionTypes.GET_ADMIN_USER_DETAILS_SUCCESS;
  constructor(public adminUserDetails?: any) { }
}

export class CountriesAction implements Action {
  readonly type = ActionTypes.LOAD_COUNTRIES;
  constructor() { }
}

export class CountriesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_COUNTRIES_SUCCESS;
  constructor(public countries?: Countries) { }
}

export class TimezoneAction implements Action {
  readonly type = ActionTypes.LOAD_TIMEZONE;
  constructor() { }
}

export class TimezoneSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_TIMEZONE_SUCCESS;
  constructor(public timezone?: any) { }
}

export class PlanAction implements Action {
  readonly type = ActionTypes.LOAD_PLAN;
  constructor() { }
}

export class PlanSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PLAN_SUCCESS;
  constructor(public plan?: any) { }
}


export class GetCampaignAction implements Action {
  readonly type = ActionTypes.GET_CAMPAIGN_TYPE;
  constructor() { }
}

export class GetCampaignSuccessAction implements Action {
  readonly type = ActionTypes.GET_CAMPAIGN_TYPE_SUCCESS;
  constructor(public campaignType: any) { }
}

export class FailureAction implements Action {
  readonly type = ActionTypes.FAILURE;
  constructor(public payload: { type: string, error: string }) { }
}


export type Actions =
  PopupNotificationAction
  | CountriesAction
  | CountriesSuccessAction
  | TimezoneAction
  | TimezoneSuccessAction
  | PlanAction
  | PlanSuccessAction
  | GetCampaignAction
  | GetCampaignSuccessAction
  // | UserDetailsAction
  // | UserDetailsSuccessAction
  | AdminUserDetailsAction
  | AdminUserDetailsSuccessAction
  | FailureAction;


