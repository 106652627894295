import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as authActions from './actions';
import { switchMap, map, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { State } from '../../root-state';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private authService: AuthenticationService) { }

  @Effect({ dispatch: false })
  public LogOut: Observable<any> = this.actions$.pipe(
    ofType(authActions.AuthActionTypes.LOGOUT),
    tap((user) => {
      localStorage.removeItem('token');
    })
  );
}
