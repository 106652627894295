export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboards',
          root: true,
          alignment: 'left',
          page: 'dashboard',
          translate: 'MENU.DASHBOARD',
        },
        {
          title: 'Components',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Google Material Remove',
              bullet: 'dot',
              icon: 'flaticon-interface-7',
              submenu: [
                {
                  title: 'Form Controls',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Auto Complete',
                      page: '',
                      permission: ''
                    }]
                },
                {
                  title: 'Navigation',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Menu',
                      page: ''
                    }
                  ]
                },
                {
                  title: 'Layout',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Card',
                      page: ''
                    }]
                },
                {
                  title: 'Buttons & Indicators',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Button',
                      page: ''
                    }]
                },
                {
                  title: 'Popups & Modals',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Bottom sheet',
                      page: ''
                    }]
                },
                {
                  title: 'Data table',
                  bullet: 'dot',
                  submenu: []
                }
              ]
            },
            {
              title: 'Ng-Bootstrap',
              bullet: 'dot',
              icon: 'flaticon-web',
              submenu: [
                {
                  title: 'Accordion',
                  page: 'ngbootstrap/accordion'
                },
                {
                  title: 'Alert',
                  page: 'ngbootstrap/alert'
                },
                {
                  title: 'Buttons',
                  page: 'ngbootstrap/buttons'
                },
                {
                  title: 'Carousel',
                  page: 'ngbootstrap/carousel'
                },
                {
                  title: 'Collapse',
                  page: 'ngbootstrap/collapse'
                },
                {
                  title: 'Datepicker',
                  page: 'ngbootstrap/datepicker'
                },
                {
                  title: 'Dropdown',
                  page: 'ngbootstrap/dropdown'
                },
                {
                  title: 'Modal',
                  page: 'ngbootstrap/modal'
                },
                {
                  title: 'Pagination',
                  page: 'ngbootstrap/pagination'
                },
                {
                  title: 'Popover',
                  page: 'ngbootstrap/popover'
                },
                {
                  title: 'Progressbar',
                  page: 'ngbootstrap/progressbar'
                },
                {
                  title: 'Rating',
                  page: 'ngbootstrap/rating'
                },
                {
                  title: 'Tabs',
                  page: 'ngbootstrap/tabs'
                },
                {
                  title: 'Timepicker',
                  page: 'ngbootstrap/timepicker'
                },
                {
                  title: 'Tooltips',
                  page: 'ngbootstrap/tooltip'
                },
                {
                  title: 'Typehead',
                  page: 'ngbootstrap/typehead'
                }
              ]
            },
          ]
        },
        {
          title: 'Applications',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'eCommerce',
              bullet: 'dot',
              icon: 'flaticon-business',
              permission: 'accessToECommerceModule',
              submenu: [
                {
                  title: 'Customers',
                  page: 'ecommerce/customers'
                },
                {
                  title: 'Products',
                  page: 'ecommerce/products'
                },
              ]
            },
            {
              title: 'User Management',
              bullet: 'dot',
              icon: 'flaticon-user',
              submenu: [
                {
                  title: 'Users',
                  page: 'user-management/users'
                },
                {
                  title: 'Roles',
                  page: 'user-management/roles'
                }
              ]
            },
          ]
        },
        {
          title: 'Custom',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Error Pages',
              bullet: 'dot',
              icon: 'flaticon2-attention',
              submenu: [
                {
                  title: 'Error 1',
                  page: 'error/error-v1'
                },
                {
                  title: 'Error 2',
                  page: 'error/error-v2'
                },
                {
                  title: 'Error 3',
                  page: 'error/error-v3'
                },
                {
                  title: 'Error 4',
                  page: 'error/error-v4'
                },
                {
                  title: 'Error 5',
                  page: 'error/error-v5'
                },
                {
                  title: 'Error 6',
                  page: 'error/error-v6'
                },
              ]
            },
            {
              title: 'Wizard',
              bullet: 'dot',
              icon: 'flaticon2-mail-1',
              submenu: [
                {
                  title: 'Wizard 1',
                  page: 'wizard/wizard-1'
                },
                {
                  title: 'Wizard 2',
                  page: 'wizard/wizard-2'
                },
                {
                  title: 'Wizard 3',
                  page: 'wizard/wizard-3'
                },
                {
                  title: 'Wizard 4',
                  page: 'wizard/wizard-4'
                },
              ]
            },
          ]
        },
      ]
    },
    aside: {
      self: {},
      items: [
        // {
        //   title: 'Dashboard',
        //   root: true,
        //   icon: 'flaticon2-architecture-and-city',
        //   page: 'dashboard',
        //   roles: ['user'],
        //   // submenu: [
        //   //   {
        //   //     title: 'Wizard 1',
        //   //     page: 'wizard/wizard-1'
        //   //   },
        //   //   {
        //   //     title: 'Wizard 2',
        //   //     page: 'wizard/wizard-2'
        //   //   },
        //   //   {
        //   //     title: 'Wizard 3',
        //   //     page: 'wizard/wizard-3'
        //   //   },
        //   //   {
        //   //     title: 'Wizard 4',
        //   //     page: 'wizard/wizard-4'
        //   //   },
        //   // ],
        //   translate: 'MENU.DASHBOARD',
        //   bullet: 'dot',
        // },
        // {
        //   title: 'Dashboard',
        //   root: true,
        //   icon: 'flaticon2-architecture-and-city',
        //   page: 'admin/dashboard',
        //   roles: ['admin'],
        //   translate: 'MENU.DASHBOARD',
        //   bullet: 'dot',
        // },
        {
          title: 'Peoples',
          root: true,
          roles: ['user'],
          icon: 'flaticon-users',
          page: 'people-list'
        },
        {
          title: 'Reminders',
          root: true,
          roles: ['user'],
          icon: 'flaticon-alarm-1',
          page: 'reminders-list'
        },
        // {
        //   title: 'My Profile',
        //   root: true,
        //   roles: ['user'],
        //   icon: 'flaticon-profile',
        //   page: 'user'
        // }
        // { section: 'Components' },
        // {
        //   title: 'Google Material Remove',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-browser-2',
        //   submenu: [
        //     {
        //       title: 'Form Controls',
        //       bullet: 'dot',
        //       submenu: [],
        //     },
        //     {
        //       title: 'Navigation',
        //       bullet: 'dot',
        //       submenu: []
        //     },
        //     {
        //       title: 'Layout',
        //       bullet: 'dot',
        //       submenu: []
        //     },
        //     {
        //       title: 'Buttons & Indicators',
        //       bullet: 'dot',
        //       submenu: []
        //     },
        //     {
        //       title: 'Popups & Modals',
        //       bullet: 'dot',
        //       submenu: []
        //     },
        //     {
        //       title: 'Data table',
        //       bullet: 'dot',
        //       submenu: []
        //     }
        //   ]
        // },
        // {
        //   title: 'Ng-Bootstrap',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-digital-marketing',
        //   submenu: [
        //     {
        //       title: 'Accordion',
        //       page: 'ngbootstrap/accordion'
        //     },
        //     {
        //       title: 'Alert',
        //       page: 'ngbootstrap/alert'
        //     },
        //     {
        //       title: 'Buttons',
        //       page: 'ngbootstrap/buttons'
        //     },
        //     {
        //       title: 'Carousel',
        //       page: 'ngbootstrap/carousel'
        //     },
        //     {
        //       title: 'Collapse',
        //       page: 'ngbootstrap/collapse'
        //     },
        //     {
        //       title: 'Datepicker',
        //       page: 'ngbootstrap/datepicker'
        //     },
        //     {
        //       title: 'Dropdown',
        //       page: 'ngbootstrap/dropdown'
        //     },
        //     {
        //       title: 'Modal',
        //       page: 'ngbootstrap/modal'
        //     },
        //     {
        //       title: 'Pagination',
        //       page: 'ngbootstrap/pagination'
        //     },
        //     {
        //       title: 'Popover',
        //       page: 'ngbootstrap/popover'
        //     },
        //     {
        //       title: 'Progressbar',
        //       page: 'ngbootstrap/progressbar'
        //     },
        //     {
        //       title: 'Rating',
        //       page: 'ngbootstrap/rating'
        //     },
        //     {
        //       title: 'Tabs',
        //       page: 'ngbootstrap/tabs'
        //     },
        //     {
        //       title: 'Timepicker',
        //       page: 'ngbootstrap/timepicker'
        //     },
        //     {
        //       title: 'Tooltips',
        //       page: 'ngbootstrap/tooltip'
        //     },
        //     {
        //       title: 'Typehead',
        //       page: 'ngbootstrap/typehead'
        //     }
        //   ]
        // },
        // { section: 'Applications' },
        // {
        //   title: 'eCommerce',
        //   bullet: 'dot',
        //   icon: 'flaticon2-list-2',
        //   root: true,
        //   permission: 'accessToECommerceModule',
        //   submenu: [
        //     {
        //       title: 'Customers',
        //       page: 'ecommerce/customers'
        //     },
        //     {
        //       title: 'Products',
        //       page: 'ecommerce/products'
        //     },
        //   ]
        // },
        // {
        //   title: 'User Management',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-user-outline-symbol',
        //   submenu: [
        //     {
        //       title: 'Users',
        //       page: 'user-management/users'
        //     },
        //     {
        //       title: 'Roles',
        //       page: 'user-management/roles'
        //     }
        //   ]
        // },
        // { section: 'Custom' },
        // {
        //   title: 'Error Pages',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-attention',
        //   submenu: [
        //     {
        //       title: 'Error 1',
        //       page: 'error/error-v1'
        //     },
        //     {
        //       title: 'Error 2',
        //       page: 'error/error-v2'
        //     },
        //     {
        //       title: 'Error 3',
        //       page: 'error/error-v3'
        //     },
        //     {
        //       title: 'Error 4',
        //       page: 'error/error-v4'
        //     },
        //     {
        //       title: 'Error 5',
        //       page: 'error/error-v5'
        //     },
        //     {
        //       title: 'Error 6',
        //       page: 'error/error-v6'
        //     },
        //   ]
        // },
        // {
        //   title: 'Wizard',
        //   root: true,
        //   bullet: 'dot',
        //   icon: 'flaticon2-mail-1',
        //   submenu: [
        //     {
        //       title: 'Wizard 1',
        //       page: 'wizard/wizard-1'
        //     },
        //     {
        //       title: 'Wizard 2',
        //       page: 'wizard/wizard-2'
        //     },
        //     {
        //       title: 'Wizard 3',
        //       page: 'wizard/wizard-3'
        //     },
        //     {
        //       title: 'Wizard 4',
        //       page: 'wizard/wizard-4'
        //     },
        //   ]
        // },
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
