import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Service
import { BaseService } from '../../core/services/base.service';
import { TokenStorage } from '../../auth/services/token-storage.service';

// Rxjs
import { Observable } from 'rxjs';

// Constant, util, environment.
import { environment } from '../../../../environments/environment';
import { API_PATH } from '../../core/constants/api-constant';
import { CommonUtil } from '../../shared/models/common.util';
import { CommonFunction } from '../../shared/common-function/common-function';

@Injectable()
export class PeopleListService extends BaseService {

  constructor(
    protected tokenStorage: TokenStorage,
    protected httpClient: HttpClient,
    protected commonFunction: CommonFunction

  ) {
    super(httpClient, tokenStorage, commonFunction);
  }

  getAllPeopleList(): Observable<any> {
    const url = environment.baseURL + environment.version + 'people';
    return this.get(url);
  }

  addPerson(data: any): Observable<any> {
    const url = environment.baseURL + environment.version + 'people';
    return this.post(url, data);
  }

  getPersonById(person_id: number): Observable<any[]> {
    const url = environment.baseURL + environment.version + 'people/' + person_id;
    return this.get(url);
  }

  updatePerson(data: any): Observable<any> {
    const url = environment.baseURL + environment.version + 'people';
    return this.put(url, data);
  }

  // tslint:disable-next-line: variable-name
  deletePerson(data) {
    const url = environment.baseURL + environment.version + 'people/' + data.personId;
    return this.delete(url);
  }

  addCustomField(data) {
    const url = environment.baseURL + environment.version + 'people/custom-field';
    return this.post(url, data);
  }

  addNewNote(data) {
    const url = environment.baseURL + environment.version + 'people/add-note';
    return this.post(url, data);
  }

  updateNote(data) {
    const url = environment.baseURL + environment.version + 'people/note';
    return this.put(url, data);
  }

  deleteNote(noteId) {
    const url = environment.baseURL + environment.version + 'people/note/' + noteId;
    return this.delete(url);
  }

  addSelectField(data) {
    const url = environment.baseURL + environment.version + 'people/add-select-field';
    return this.post(url, data);
  }

  getAllSelectFields(data) {
    const url = environment.baseURL + environment.version + `people/list/single-select-option/${data.field_name}`;
    return this.get(url);
  }

  getSelectFields() {
    const url = environment.baseURL + environment.version + `people/list/select-fields`;
    return this.get(url);
  }

  addSingleSelectField(data) {
    const url = environment.baseURL + environment.version + 'people/add-single-select';
    return this.post(url, data);
  }

  deleteSelectField(data) {
    const url = environment.baseURL + environment.version + 'people/update-single-select';
    return this.patch(url, data);
  }

  deleteSelectCustomField(data) {
    const url = environment.baseURL + environment.version + 'people/update-select-field';
    return this.patch(url, data);
  }

  UpdateSelectCustomField(data) {
    const url = environment.baseURL + environment.version + 'people/update-select-field';
    return this.patch(url, data);
  }

  getAdditionalFieldById(person_id) {
    const url = environment.baseURL + environment.version + `people/select/field/${person_id}`;
    return this.get(url);
  }

  getSelectOptions() {
    const url = environment.baseURL + environment.version + `people/list/select-fields`;
    return this.get(url);
  }


  getCustomeFieldSelectedData() {
    return this.httpClient.get(environment.baseURL + environment.version + 'people/list/select-fields')
  }


  //Notification Api

  getAllReminderList() {
    return this.httpClient.get(environment.baseURL + environment.version + 'mobile/list/reminders')
  }

  markAsDone(reminderId) {
    return this.httpClient.put(environment.baseURL + environment.version + `mobile/mark-reminder-done/${reminderId}`,{})
  }

  snoozeForDay(reminderId) {
    return this.httpClient.put(environment.baseURL + environment.version + `mobile/snooze-reminder-for-day/${reminderId}`,{})
  }
}
