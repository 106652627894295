// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseDomainUrl = "http://164.90.142.40/";

const devBaseURL = "http://164.90.142.40/";
//const devBaseURL = 'http://oneclicksales.xyz:30101/';
// const devBaseURL = 'http://8d4c-110-227-255-58.ngrok.io/';
// const devBaseURL = 'http://d6e4-110-227-255-58.ngrok.io/';
// const devBaseURL = 'http://people-profile.jobase.io:3001/';
// const devBaseURL = 'https://api-people-profile.jobase.io/';
const version = "v1/";

const userPath = ``;

export const environment = {
	baseDomainUrl,
	production: false,
	devBaseURL,
	baseURL: `${devBaseURL}`,
	userPath,
	version,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
