import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { State } from '../root-state';

@Injectable({
  providedIn: 'root'
})
export class AuthStoreService {

  constructor(
    private store: Store<State>
  ) { }

  selectAuth(): Observable<any> {
    return this.store.pipe(select(selectors.selectRegister));
  }

  getRegisterInfo(): Observable<any> {
    return this.store.pipe(select(selectors.getRegisterInfo));
  }

  getRegisterUserInfo(): Observable<any> {
    return this.store.pipe(select(selectors.getRegisterUserInfo));
  }

  /************** Dispatchers ************** */

  dispatchRegisterInfo(userDetails: any) {
    this.store.dispatch(new actions.RegisterAction(userDetails));
  }

  dispatchRegisterUserDetail(userDetails: any) {
    this.store.dispatch(new actions.RegisterUserAction(userDetails));
  }

  dispatchResetRegister() {
    this.store.dispatch(new actions.ResetRegister());
  }

  dispatchLogout() {
    this.store.dispatch(new actions.LogOut());
  }
}
