// ANGULAR
import { Injectable, Query } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// RXJS
import { Observable, of, Subject } from "rxjs";
import { tap } from "rxjs/operators";

// SERVICES
import { BaseService } from "../../core/services/base.service";
import { TokenStorage } from "./token-storage.service";
import { GlobalStoreService } from "../../global/global-store.service";

// CONST
import { environment } from "../../../../environments/environment";
import { API_PATH } from "../../core/constants/api-constant";
import { AccessData } from "../../../@metronic/core/_auth/access-data";
import { LoginCredential } from "../../../@metronic/core/_auth/credential";
import { CommonFunction } from "../../shared/common-function/common-function";

@Injectable()
export class AuthenticationService extends BaseService {
	API_ENDPOINT_CREATE_ACCOUNT = "/create-account";
	public onCredentialUpdated$: Subject<AccessData>;

	constructor(public http: HttpClient, public baseService: BaseService, public tokenStorage: TokenStorage, private globalStoreService: GlobalStoreService, protected commonFunction: CommonFunction) {
		super(http, tokenStorage, commonFunction);
		this.onCredentialUpdated$ = new Subject();
	}

	/**
	 * Check, if user already authorized.
	 * @description Should return Observable with true or false values
	 * @returns Observable<boolean>
	 * @memberOf AuthService
	 */
	public isAuthorized(): Observable<boolean> {
		const accessToken = this.tokenStorage.getAccessToken();
		const auth: any = {
			isLoggedIn: !accessToken ? false : true,
		};
		return of(auth);
	}

	/**
	 * Submit register request
	 * @param data: user entered data
	 * @returns Observable<any>
	 */
	public register(data: any): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.REGISTER}`, data).pipe(tap((n) => this.saveAccessData(n)));
	}

	public checkUserEmail(email: any): Observable<any> {
		return this.get(`${environment.baseURL}${environment.version}${API_PATH.CHECK_USER_EMAIL}?user_email=${email}`);
	}

	/**
	 * Submit login request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public login(credential: LoginCredential): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.LOGIN_POST}`, credential).pipe(tap((n) => this.saveAccessData(n)));
	}

	/**
	 * Submit logout request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public logout(credential: any): Observable<any> {
		return this.patch(`${environment.baseURL}${environment.version}${API_PATH.LOGOUT}`, undefined);
	}

	/**
	 * Submit Direct Login request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	directLogin(credential: LoginCredential): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.DIRECT_LOGIN_POST}`, credential).pipe(tap((n) => this.saveAccessData(n)));
	}

	/**
	 * Submit forgotPassword request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public forgotPassword(credential: any): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.FORGET_PASSWORD}`, credential);
	}

	/**
	 * Submit forgotPassword request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public resendLink(credential: any): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.RESEND_LINK}`, credential);
	}

	/**
	 * Submit forgotPassword request
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public resetPassword(credential: any): Observable<any> {
		return this.post(`${environment.baseURL}${environment.version}${API_PATH.RESET_PASSWORD}`, credential);
	}

	/**
	 * Submit checkResetPasswordToken request
	 * @param token: query parm
	 * @returns Observable<any>
	 */
	public checkResetPasswordToken(token: any): Observable<any> {
		return this.http.get(`${environment.baseURL}${environment.version}${API_PATH.RESET_PASSWORD}?usst_reset_token=${token}`);
	}

	/**
	 * Submit checkResetPasswordToken request
	 * @param token: query parm
	 * @returns Observable<any>
	 */
	public verifyAccount(token: any): Observable<any> {
		return this.http.get(`${environment.baseURL}${environment.version}${API_PATH.VERIFY_ACCOUNT}?usst_verify_token=${token}`);
	}

	/**
	 * Submit Change password
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public changePassword(credential: any): Observable<any> {
		return this.http.patch(`${environment.baseURL}${environment.version}${API_PATH.CHANGE_PASSWORD}`, credential);
	}

	/**
	 * Update User Information
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public getUserDetails() {
		return this.http.get(`${environment.baseURL}${environment.version}${API_PATH.GET_USER_DETAILS}`);
	}

	/**
	 * Update User Information
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public updateUserDetails(credential: any): Observable<any> {
		return this.http.patch(`${environment.baseURL}${environment.version}${API_PATH.UPDATE_USER_DETAILS}`, credential);
	}

	/**
	 * Update Organization Details
	 * @param Credential: credential
	 * @returns Observable<any>
	 */
	public updateOrganizationDetail(credential: any): Observable<any> {
		return this.http.patch(`${environment.baseURL}${environment.version}${API_PATH.UPDATE_ORG}`, credential);
	}

	/**
	 * Get Organization Details
	 * @returns Observable<any>
	 */
	public getOrganizationDetail() {
		return this.http.get(`${environment.baseURL}${environment.version}${API_PATH.GET_ORG}`);
	}

	/**
	 * Save access data in the storage
	 * @param AccessData: data
	 */
	public saveAccessData(accessData) {
		if (typeof accessData !== "undefined") {
			this.tokenStorage
				// .setUserInfo(accessData.data.user)
				.setAccessToken(accessData.token)
				.setRefreshToken("");
			// .setUserID(accessData.data.user.user_id)
			this.onCredentialUpdated$.next(accessData);
		}
		return this;
	}

	/**
	 * Save User Payment Details
	 * @param payload: payload
	 */
	public userPayment(paymentDetails): Observable<any> {
		return this.patch(`${environment.baseURL}${environment.version}${API_PATH.UPDATE_USER_PAYMENT}`, paymentDetails);
	}

	tokenRegister(jwtToken: string, credential): Observable<any> {
		return this.http.post(`${environment.baseURL}create-account`, {
			name: credential.name,
			email: credential.email,
			company: credential.company,
			title: credential.title,
			businessFunction: credential.businessFunction,
			noOfUsers: credential.noOfUsers,
			plan: credential.plan,
			token: jwtToken,
		});
	}
}
