// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Rxjs
import { Observable } from 'rxjs';

// Services
import { BaseService } from '../../core/services/base.service';
import { TokenStorage } from '../../auth/services/token-storage.service';

// Constant, environment
import { API_PATH } from '../../core/constants/api-constant';
import { environment } from '../../../../environments/environment';
import { CommonFunction } from '../../shared/common-function/common-function';


@Injectable()
export class GlobalService extends BaseService {

  constructor(protected tokenStorage: TokenStorage, protected httpClient: HttpClient,
    protected commonFunction: CommonFunction) {
    super(httpClient, tokenStorage,commonFunction);
  }

  getUserDetails(): Observable<any> {
    const url = environment.baseURL + environment.version +
      API_PATH.GET_USER_DETAILS;
    return this.get(url);
  }

  getAdminUserDetails(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.GET_ADMIN_USER_DETAILS;
    return this.get(url);
  }

  getAllCountries(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.COUNTRY;
    return this.get(url);
  }

  getTimeZone(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.TIME_ZONE;
    return this.get(url);
  }

  getPlan(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.PLAN;
    return this.get(url);
  }

  public getCampaignType(): Observable<any> {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.CAMPAIGN_TYPE;
    return this.get(url);
  }

  public imageUpload(filePath) {
    const url = environment.baseURL + environment.version + '/' +
      API_PATH.IMAGE_UPLOAD;
    return this.upload(url, filePath);
  }

}
