import { Injectable } from '@angular/core';
import * as actions from './store/actions';
import * as selectors from './store/selectors';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { State } from '../root-state';
import { ToastNotification } from './models/toast-notiifcation.interface';
import { Countries } from './models/countries.interface';

@Injectable()
export class GlobalStoreService {

  constructor(
    protected store: Store<State>
  ) {
  }

  selectToastNotification(): Observable<ToastNotification> {
    return this.store.pipe(select(selectors.selectToastNotification));
  }

  selectGetUserDetails(): Observable<any> {
    return this.store.pipe(select(selectors.selectGetUserDetails));
  }

  selectGetAdminUserDetails(): Observable<any> {
    return this.store.pipe(select(selectors.selectGetAdminUserDetails));
  }

  selectGetCountries(): Observable<Countries> {
    return this.store.pipe(select(selectors.selectGetCountries));
  }

  selectGetTimezoneList(): Observable<any> {
    return this.store.pipe(select(selectors.selectGetTimezone));
  }

  selectGetPlan(): Observable<any> {
    return this.store.pipe(select(selectors.selectGetPlan));
  }

  selectCampaignTypeList(): Observable<any> {
    return this.store.pipe(select(selectors.selectCampaignTypeList));
  }

  /************** Dispatchers ************** */

  // dispatchGetUserDetails() {
  //   this.store.dispatch(new actions.UserDetailsAction());
  // }

  dispatchGetAdminUserDetails() {
    this.store.dispatch(new actions.AdminUserDetailsAction());
  }

  dispatchGetCountriesList() {
    this.store.dispatch(new actions.CountriesAction());
  }

  dispatchGetTimezoneList() {
    this.store.dispatch(new actions.TimezoneAction());
  }

  dispatchGetPlanList() {
    this.store.dispatch(new actions.PlanAction());
  }

  dispatchGetCampaignTypeList() {
    this.store.dispatch(new actions.GetCampaignAction());
  }

}
