// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// RoutingModule
import { UiModulesRouting } from './ui-modules-routing.module';
import { ThemeModule } from '../@metronic/views/themes/demo1/theme.module';
import { CoreModule } from './core/core.module';

@NgModule({
    imports: [
        CommonModule,
        ThemeModule,
        UiModulesRouting,
        CoreModule
    ],
    declarations: [],
    exports: [UiModulesRouting],
})
export class UIModule { }
