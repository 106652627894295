// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kt-update-status-dialog',
  templateUrl: './update-status-dialog.component.html'
})
export class UpdateStatusDialogComponent implements OnInit {
  selectedStatusForUpdate = new FormControl('');
  viewLoading = false;
  loadingAfterSubmit = false;
  constructor() { }

  ngOnInit() {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    setTimeout(() => {
      this.viewLoading = false;
    }, 2500);
  }

  onNoClick(): void {
  }

  updateStatus() {
    if (this.selectedStatusForUpdate.value.length === 0) {
      return;
    }

    /* Server loading imitation. Remove this */
    this.viewLoading = true;
    this.loadingAfterSubmit = true;
  }
}
