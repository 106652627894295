export const hebrewLocale = {
    "hebrewMonthNames": [
        {
            1: 'Tishri',
            2: 'Heshvan',
            3: 'Kislev',
            4: 'Tevet',
            5: 'Shevat',
            6: 'AdarI',
            7: 'Nisan',
            8: 'Iyyar',
            9: 'Sivan',
            10: 'Tammuz',
            11: 'Av',
            12: 'Elul',
        }
    ],
    "hebrewMonthNamesLeap": [
        {
            1: 'Tishri',
            2: 'Heshvan',
            3: 'Kislev',
            4: 'Tevet',
            5: 'Shevat',
            6: 'AdarI',
            7: 'AdarII',
            8: 'Nisan',
            9: 'Iyyar',
            10: 'Sivan',
            11: 'Tammuz',
            12: 'Av',
            13: 'Elul',
        }
    ],
    "monthNamesInHebrew": [
        {
            'Tishri': 'תשרי',
            'Heshvan': 'חשוון',
            'Kislev': 'כִּסְלֵו',
            'Tevet': 'טבת',
            'Shevat': 'שבט',
            'AdarI': 'אדר א׳',
            'Nisan': 'ניסן',
            'Iyyar': 'אייר',
            'Sivan': 'סִיוָן',
            'Tammuz': 'תמוז',
            'Av': 'אָב',
            'Elul': 'אֱלוּל'
        }
    ],
    "monthNamesInHebrewLeap": [
        {
            'Tishri': 'תשרי',
            'Heshvan': 'חשוון',
            'Kislev': 'כִּסְלֵו',
            'Tevet': 'טבת',
            'Shevat': 'שבט',
            'AdarI': 'אדר א׳',
            'AdarII': 'אדר ב׳',
            'Nisan': 'ניסן',
            'Iyyar': 'אייר',
            'Sivan': 'סִיוָן',
            'Tammuz': 'תמוז',
            'Av': 'אָב',
            'Elul': 'אֱלוּל'
        }
    ],
    "monthNamesInHebrewToEnglish": [
        {
            'תשרי': 'Tishri',
            'חשוון': 'Heshvan',
            'כִּסְלֵו': 'Kislev',
            'טבת': 'Tevet',
            'שבט': 'Shevat',
            'אדר א׳': 'AdarI',
            'ניסן': 'Nisan',
            'אייר': 'Iyyar',
            'סִיוָן': 'Sivan',
            'תמוז': 'Tammuz',
            'אָב': 'Av',
            'אֱלוּל': 'Elul'
        }
    ],
    "monthNamesInHebrewToEnglishLeap": [
        {
            'תשרי': 'Tishri',
            'חשוון': 'Heshvan',
            'כִּסְלֵו': 'Kislev',
            'טבת': 'Tevet',
            'שבט': 'Shevat',
            'אדר א׳': 'AdarI',
            'אדר ב׳': 'AdarII',
            'ניסן': 'Nisan',
            'אייר': 'Iyyar',
            'סִיוָן': 'Sivan',
            'תמוז': 'Tammuz',
            'אָב': 'Av',
            'אֱלוּל': 'Elul'
        }
    ],
    "generalMonthNamesInEnglish": [
        {
            'Tishri': 'Tishri',
            'Heshvan': 'Heshvan',
            'Kislev': 'Kislev',
            'Tevet': 'Tevet',
            'Shevat': 'Shevat',
            'AdarI': 'AdarI',
            'Nisan': 'Nisan',
            'Iyyar': 'Iyyar',
            'Sivan': 'Sivan',
            'Tammuz': 'Tammuz',
            'Av': 'Av',
            'Elul': 'Elul'
        }
    ],
    "generalMonthNamesInEnglishLeap": [
        {
            'Tishri': 'Tishri',
            'Heshvan': 'Heshvan',
            'Kislev': 'Kislev',
            'Tevet': 'Tevet',
            'Shevat': 'Shevat',
            'AdarI': 'AdarI',
            'AdarII': 'AdarII',
            'Nisan': 'Nisan',
            'Iyyar': 'Iyyar',
            'Sivan': 'Sivan',
            'Tammuz': 'Tammuz',
            'Av': 'Av',
            'Elul': 'Elul'
        }
    ],
    "hebrewMonthToKey": [
        {
            1: 'תשרי',
            2: 'חשוון',
            3: 'כִּסְלֵו',
            4: 'טבת',
            5: 'שבט',
            6: 'אדר א׳',
            7: 'ניסן',
            8: 'אייר',
            9: 'סִיוָן',
            10: 'תמוז',
            11: 'אָב',
            12: 'אֱלוּל'
        }
    ],
    "hebrewMonthToKeyLeap": [
        {
            1: 'תשרי',
            2: 'חשוון',
            3: 'כִּסְלֵו',
            4: 'טבת',
            5: 'שבט',
            6: 'אדר א׳',
            7: 'אדר ב׳',
            8: 'ניסן',
            9: 'אייר',
            10: 'סִיוָן',
            11: 'תמוז',
            12: 'אָב',
            13: 'אֱלוּל'
        }
    ],
    "closeText": "סגור",
    "prevText": "הקודם",
    "nextText": "הבא",
    "monthNames": [
        "ינואר",
        "פברואר",
        "מרץ",
        "אפריל",
        "מאי",
        "יוני",
        "יולי",
        "אוגוסט",
        "ספטמבר",
        "אוקטובר",
        "נובמבר",
        "דצמבר"
    ],
    "monthNamesShort": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12"
    ],
    "dayNames": [
        "ראשון",
        "שני",
        "שלישי",
        "רביעי",
        "חמישי",
        "שישי",
        "שבת"
    ],
    "dayNamesShort": [
        "א\"",
        "ב\"",
        "ג\"",
        "ד\"",
        "ה\"",
        "ו\"",
        "שבת"
    ],
    "dayNamesMin": [
        "א\"",
        "ב\"",
        "ג\"",
        "ד\"",
        "ה\"",
        "ו\"",
        "שבת"
    ],
    "weekHeader": "שבוע",
    "firstDayOfWeek": 0,
    "firstDay": 1,
    "isRTL": false,
    "showMonthAfterYear": false,
    "yearSuffix": "",
    "timeOnlyTitle": "זמן בלבד",
    "timeText": "זמן",
    "hourText": "שעה",
    "minuteText": "דקה",
    "secondText": "שניה",
    "currentText": "היום",
    "ampm": false,
    "month": "חודש",
    "week": "שבוע",
    "day": "יום",
    "allDayText": "כל היום"
}
