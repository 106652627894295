// Angular
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'kt-delete-entity-dialog',
  templateUrl: './delete-entity-dialog.component.html'
})
export class DeleteEntityDialogComponent implements OnInit {
  // Public properties
  viewLoading = false;

/**
 * Component constructor
 *
 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
 * @param data: any
 */
  constructor(
  ) { }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
  ngOnInit() {
  }

/**
 * Close dialog with false result
 */
  onNoClick(): void {
  }

/**
 * Close dialog with true result
 */
  onYesClick(): void {
    /* Server loading imitation. Remove this */
    this.viewLoading = true;
  }
}
