import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';

import { DashboardState } from './state';
import { state } from '@angular/animations';

export const selectState: MemoizedSelector<
    object,
    DashboardState
> = createFeatureSelector<DashboardState>('dashboard');

const getDashboardCampaignCount = (state: DashboardState): any => state && state.campaignsCount && state.campaignsCount.data && state.campaignsCount.data;
export const selectDashboardCampaignCount: MemoizedSelector<object, any> = createSelector(
    selectState,
    getDashboardCampaignCount
);

const getDashboardCampaignGameStatusCount = (state: DashboardState): any => state && state.campaignGameStatusCount && state.campaignGameStatusCount.data && state.campaignGameStatusCount.data;
export const selectDashboardCampaignGameStatusCount: MemoizedSelector<object, any> = createSelector(
    selectState,
    getDashboardCampaignGameStatusCount
);


const getIsLoading = (state: DashboardState): boolean => state.isLoading;
export const selectLoading: MemoizedSelector<object, any> = createSelector(
    selectState,
    getIsLoading
);

const getIsSuccess = (state: DashboardState): boolean => !state.isLoading && state.error == null;
export const selectIsSuccess: MemoizedSelector<object, any> = createSelector(
    selectState,
    getIsSuccess
);


