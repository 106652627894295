export const MESSAGE_CONSTANTS = {
    /** 
     * ****************************
     * PERSON
     * ***************************
     */
    PERSON_TITLE: 'Person',
    // ADD
    PERSON_ADD_SUCCESS: 'Person added successfully.',
    // UPDATE
    PERSON_UPDATE_SUCCESS: 'Person updated successfully.',
    // DELETE
    PERSON_DELETE_SUCCESS: 'Person deleted successfully.',

    NOTE_ADD_SUCCESS: 'Note added successfully.',
    NOTE_DELETE_SUCCESS: 'Note deleted successfully',

    USER_TITLE: 'User',
    UPDATE_USER_STATUS_SUCCESS: 'User updated successfully.',
};
