// Angular
import { Component, Input, OnInit } from '@angular/core';
// RxJS
import { Observable } from 'rxjs';

@Component({
  selector: 'kt-user-profile2',
  templateUrl: './user-profile2.component.html',
})
export class UserProfile2Component implements OnInit {
  // Public properties
  user$: Observable<any>;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

/**
 * Component constructor
 *
 * @param store: Store<AppState>
 */
  constructor() {
  }

/**
 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
 */

/**
 * On init
 */
  ngOnInit(): void {
    // 	this.user$ = this.store.pipe(select(currentUser));
  }

/**
 * Log out
 */
  logout() {
    console.log('LOGOUT');
  }
}
