import {
    createFeatureSelector,
    createSelector,
    MemoizedSelector
} from '@ngrx/store';

import { GlobalState } from './state';
import { Countries } from '../models/countries.interface';
import { ToastNotification } from '../models/toast-notiifcation.interface';
import { of } from 'rxjs';

export const selectState: MemoizedSelector<
    object,
    GlobalState
> = createFeatureSelector<GlobalState>('global');

const getIsLoading = (state: GlobalState): boolean => state.isLoading;
export const selectLoading: MemoizedSelector<object, any> = createSelector(
    selectState,
    getIsLoading
);

const getToastNotification = (state: GlobalState): ToastNotification => state.toastNotification;
export const selectToastNotification: MemoizedSelector<object, any> = createSelector(
    selectState,
    getToastNotification
);

const getUserDetails = (state: GlobalState): any => state.userDetails && state.userDetails && state.userDetails.data ? state.userDetails.data : null;
export const selectGetUserDetails: MemoizedSelector<object, any> = createSelector(
    selectState,
    getUserDetails
);

const getAdminUserDetails = (state: GlobalState): any => state.adminUserDetails && state.adminUserDetails && state.adminUserDetails.data ? state.adminUserDetails.data : null;
export const selectGetAdminUserDetails: MemoizedSelector<object, any> = createSelector(
    selectState,
    getAdminUserDetails
);

const getCountries = (state: GlobalState): Countries => state.data && state.data.data ? state.data.data.country : [];
export const selectGetCountries: MemoizedSelector<object, any> = createSelector(
    selectState,
    getCountries
);

const getTimezone = (state: GlobalState): any => state && state.data && state.data.data && state.data.data.timezone;
export const selectGetTimezone: MemoizedSelector<object, any> = createSelector(
    selectState,
    getTimezone
);

const getCampaignTypeList = (state: GlobalState): any => state && state.campType && state.campType.data && state.campType.data.campaign_type;
export const selectCampaignTypeList: MemoizedSelector<object, any> = createSelector(
    selectState,
    getCampaignTypeList
);

const getPlan = (state: GlobalState): any => state.plan && state.plan.data ? state.plan.data.plan.sort((a, b) => (a.plan_order - b.plan_order)) : [];
export const selectGetPlan: MemoizedSelector<object, any> = createSelector(
    selectState,
    getPlan
);
