import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as peopleListActions from './actions';
import * as globalActions from '../../global/store/actions';

import { switchMap, map, catchError, withLatestFrom, mergeMap } from 'rxjs/operators';
import { State } from '../../root-state';
import { PeopleListService } from '../services/people-list.service';

import { MessageType } from '../../core/models/message-type.enum';
import { MESSAGE_CONSTANTS } from '../../core/constants/message.constant';
const getEmptyAction = (action: string, reason: string) => Object.assign({ type: `[people-list] ${action} ${reason}` });

@Injectable()
export class PeopleListEffects {
  constructor(private actions$: Actions,
    private store: Store<State>,
    private peopleListService: PeopleListService
  ) { }

  @Effect()
  loadEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.LoadAllPeopleAction>(peopleListActions.ActionTypes.LOAD_ALL_PEOPLE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.getAllPeopleList()
        .pipe(
          map((items: any) => new peopleListActions.LoadAllPeopleSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
      return of(getEmptyAction('', 'already loaded'));
    })
  );

  // ADD PERSON
  @Effect()
  addPersonEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.AddPersonAction>(peopleListActions.ActionTypes.ADD_PERSON),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.addPerson(action.data)
        .pipe(
          mergeMap((items) => {
            let actions: any[] = [];
            actions = [new peopleListActions.AddPersonSuccessAction(items)];
            actions.push(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.ADD_PERSON,
              messageType: MessageType.Success,
              message: MESSAGE_CONSTANTS.PERSON_ADD_SUCCESS,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }));
            return actions;
          }),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.ADD_PERSON,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    })
  );

  @Effect()
  getPersonByIdEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.GetPersonByIdAction>(peopleListActions.ActionTypes.GET_PERSON_BY_ID),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.getPersonById(action.personid)
        .pipe(
          map((items: any) => new peopleListActions.GetPersonByIdSuccessAction(items)),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.GET_PERSON_BY_ID,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    }));


  @Effect()
  updatePersonEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.UpdatePersonAction>(peopleListActions.ActionTypes.UPDATE_PERSON),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.updatePerson(action.person)
        .pipe(
          mergeMap((items) => {
            let actions: any[] = [];
            actions = [new peopleListActions.UpdatePersonSuccessAction(items)];
            actions.push(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.UPDATE_PERSON,
              messageType: MessageType.Success,
              message: MESSAGE_CONSTANTS.PERSON_UPDATE_SUCCESS,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }));
            return actions;
          }),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.UPDATE_PERSON,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    }));


  @Effect()
  deletePersonEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.DeletePersonAction>(peopleListActions.ActionTypes.DELETE_PERSON),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.deletePerson(action.person_id)
        .pipe(
          mergeMap((items) => {
            let actions: any[] = [];
            actions = [new peopleListActions.DeletePersonSuccessAction(items)];
            actions.push(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.DELETE_PERSON,
              messageType: MessageType.Success,
              message: MESSAGE_CONSTANTS.PERSON_DELETE_SUCCESS,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }));
            return actions;
          }),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.DELETE_PERSON,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    }));

  // ADD CUSTOMFIELD
  @Effect()
  addCustomFieldEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.AddCustomFieldAction>(peopleListActions.ActionTypes.ADD_CUSTOM_FIELD),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.addCustomField(action.data)
        .pipe(
          map((items: any) => new peopleListActions.AddCustomFieldSuccessAction(items)),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.ADD_CUSTOM_FIELD,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    }));

  @Effect()
  addNewNoteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.AddNewNoteAction>(peopleListActions.ActionTypes.ADD_NEW_NOTE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.addNewNote(action.data)
        .pipe(
          map((items: any) => new peopleListActions.AddNewNoteSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
    }));

  @Effect()
  updateNoteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.UpdateNoteAction>(peopleListActions.ActionTypes.UPDATE_NOTE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.updateNote(action.data)
        .pipe(
          map((items: any) => new peopleListActions.UpdateNoteSuccessAction(items)),
          catchError(error =>
            of(new globalActions.FailureAction(error))));
    }));

  @Effect()
  deleteNoteEffect$: Observable<Action> = this.actions$.pipe(
    ofType<peopleListActions.DeleteNoteAction>(peopleListActions.ActionTypes.DELETE_NOTE),
    withLatestFrom(this.store),
    switchMap(([action, state]) => {
      return this.peopleListService.deleteNote(action.noteId)
        .pipe(
          mergeMap((items) => {
            let actions: any[] = [];
            actions = [new peopleListActions.DeleteNoteSuccessAction(items)];
            actions.push(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.DELETE_NOTE,
              messageType: MessageType.Success,
              message: MESSAGE_CONSTANTS.NOTE_DELETE_SUCCESS,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }));
            return actions;
          }),
          catchError(error =>
            of(new globalActions.PopupNotificationAction({
              actionType: peopleListActions.ActionTypes.DELETE_NOTE,
              messageType: MessageType.Error,
              message: error.error.message,
              extraParameters: null,
              title: MESSAGE_CONSTANTS.PERSON_TITLE
            }))));
    }));
}

