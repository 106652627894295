import { Action } from '@ngrx/store';

export enum ActionTypes {
    // Dashboard Count Actions
    LOAD_CAMPAIGN_COUNT = '[Dashboard] Campaign Load',
    LOAD_CAMPAIGN_COUNT_SUCCESS = '[Dashboard] Campaign Load Success',

    LOAD_CAMPAIGN_GAME_COUNT_STATUS = '[Dashboard] Campaign Game Status Count',
    LOAD_CAMPAIGN_GAME_COUNT_STATUS_SUCCESS = '[Dashboard] Campaign Game Status CountSuccess',

    FAILURE = '[Dashboard] Failure',
}

export class LoadCampaignCountAction implements Action {
    readonly type = ActionTypes.LOAD_CAMPAIGN_COUNT;
    constructor() { }
}

export class LoadCampaignCountSuccessAction implements Action {
    readonly type = ActionTypes.LOAD_CAMPAIGN_COUNT_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadCampaignGameStatusCountAction implements Action {
    readonly type = ActionTypes.LOAD_CAMPAIGN_GAME_COUNT_STATUS;
    constructor() { }
}

export class LoadCampaignGameStatusCountSuccessAction implements Action {
    readonly type = ActionTypes.LOAD_CAMPAIGN_GAME_COUNT_STATUS_SUCCESS;
    constructor(public payload: any) { }
}


export type Actions =
LoadCampaignCountAction | LoadCampaignCountSuccessAction |
LoadCampaignGameStatusCountAction | LoadCampaignGameStatusCountSuccessAction;

