import { Subscription } from 'rxjs';
// Angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, ActivationEnd } from '@angular/router';
import { Location } from '@angular/common';
// Layout
import { LayoutConfigService, SplashScreenService, TranslationService, MenuAsideService } from './@metronic/core/_base/layout';
import { TokenStorage } from './state/auth/services/token-storage.service';
// language list
import { locale as enLang } from './@metronic/core/_config/i18n/en';
import { locale as chLang } from './@metronic/core/_config/i18n/ch';
import { locale as esLang } from './@metronic/core/_config/i18n/es';
import { locale as jpLang } from './@metronic/core/_config/i18n/jp';
import { locale as deLang } from './@metronic/core/_config/i18n/de';
import { locale as frLang } from './@metronic/core/_config/i18n/fr';
import { GlobalStoreService } from './state/global/global-store.service';
import LogRocket from 'logrocket';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[kt-root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  // Public properties
  title = 'People Profile';
  loader: boolean;
  private _routerSub = Subscription.EMPTY;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param translationService: TranslationService
   * @param router: Router
   * @param layoutConfigService: LayoutCongifService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private translationService: TranslationService,
    private router: Router,
    public location: Location,
    private layoutConfigService: LayoutConfigService,
    private tokenStorage: TokenStorage,
    private menuAsideService: MenuAsideService,
    private splashScreenService: SplashScreenService,
    private globalStoreService: GlobalStoreService) {

    this._routerSub = router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ActivationEnd) {
        // Get is Admin
        this.menuAsideService.loadMenu('user');
      };
    });

    // register translations
    this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);
    if (this.tokenStorage.getAccessToken()) {
      // this.globalStoreService.dispatchGetUserDetails();
    }

  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    LogRocket.init('8d4oqh/person-profile');
    // enable/disable loader
    this.loader = this.layoutConfigService.getConfig('loader.enabled');

    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this._routerSub.unsubscribe();
  }
}
