import { Component, OnInit } from '@angular/core';
import { GlobalStoreService } from '../../../../state/global/global-store.service';
import { Subscription } from 'rxjs';
import { MessageType } from '../../../../state/core/models/message-type.enum';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'kt-toast-notification',
    templateUrl: './toast-notification.component.html',
    styleUrls: ['./toast-notification.component.scss']
})
export class ToastNotificationComponent implements OnInit {
    subscriptions: Subscription[] = [];

    constructor(private globalStoreService: GlobalStoreService, private toast: ToastrService) { }

    ngOnInit() {
        this.subscriptions.push(
            this.globalStoreService.selectToastNotification().subscribe(data => {
                if (data) {
                    this.displayMessage(data);
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    displayMessage(data: any) {
        switch (data.messageType) {
            case MessageType.Success:
                this.toast.success(data.message, data.title, { closeButton: true, timeOut: 3000 });
                break;
            case MessageType.Error:
                this.toast.error(data.message, data.title, { closeButton: true, timeOut: 3000 });
                break;
            case MessageType.Info:
                this.toast.info(data.message, data.title, { closeButton: true, timeOut: 3000 });
                break;
            case MessageType.Warning:
                this.toast.warning(data.message, data.title, { closeButton: true, timeOut: 3000 });
                break;
            default:
                this.toast.info(data.message, data.title, { closeButton: true, timeOut: 3000 });
                break;
        }
    }

}
