// Angular
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// NGX Spinner
import { NgxSpinnerModule } from 'ngx-spinner';

// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';

// Hammer JS
import 'hammerjs';

// Copmponents
import { AppComponent } from './app.component';

// Modules
import { CoreModule } from './@metronic/core/core.module';
import { CoreModule as KtCoreModule } from './modules/core/core.module';

// Partials
import { PartialsModule } from './@metronic/views/partials/partials.module';

// State
import { StateModule } from './state/state.module';

// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from './@metronic/core/_base/layout';

// CRUD
import { HttpUtilsService, LayoutUtilsService, TypesUtilsService } from './@metronic/core/_base/crud';

// Config
import { LayoutConfig } from './@metronic/core/_config/demo1/layout.config';
import { UIModule } from './modules/ui-modules.module';
import { AuthInterceptor } from './modules/shared/services/auth-interceptor';

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300,
};

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    KtCoreModule,
    UIModule,
    // AppRoutingModule,
    HttpClientModule,
    PartialsModule,
    OverlayModule,
    NgxSpinnerModule,
    // State
    StateModule,
    // AuthModule.forRoot(),
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
  ],
  exports: [],
  providers: [
    AuthInterceptor,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService], multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
