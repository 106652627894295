import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const peopleListAdapter: EntityAdapter<any> = createEntityAdapter<any>({
});

export interface PeopleListState extends EntityState<any> {
    isLoading?: boolean;
    error?: any;
    peoples?: any;
    data?: any;
    fields?: any;
    notes?: any;
}

export const initialState: PeopleListState = peopleListAdapter.getInitialState(
    {
        isLoading: false,
        error: null,
        peoples: [],
        data: [],
        fields: [],
        notes: []
    }
);
