import { Injectable } from '@angular/core';
import { AuthNoticeService } from '../../auth/services/auth-notice.service';
import { ERROR_CODE } from '../services/auth-interceptor.constant';
import * as moment from 'moment';
import { hebrewLocale } from '../../../../assets/data/he';

@Injectable({
    providedIn: 'root',
})

export class CommonFunction {

    constructor(public authNoticeService: AuthNoticeService) { }

    /**
     * Set error based on error code.
     * @param err - Error status code.
     */
    httpErrorType(err: any) {
        this.authNoticeService.setNotice(err.error.message, 'error');
        // switch (err.status) {
        //     // case ERROR_CODE.ERROR_500:
        //     //     this.authNoticeService.setNotice(err.error.message, 'error');
        //     //     break;
        //     // default:
        //     //     this.authNoticeService.setNotice(err.error.message, 'error');
        //     //     break;
        // }
    }

    /**
     * Hide all notice.
     */
    hideNotice() {
        setTimeout(() => {
            this.authNoticeService.setNotice(null);
        }, 2000);
    }

    /**
   * Scroll to top.
   */
    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    getHebrewDate(date) {
        if (!date) {
            return date;
        } else {
            let splittedDate = date.split(' ');
            let value;
            value = this.getKeyByValue(splittedDate);
            return value;
        }
    }

    getKeyByValue(object) {
        let newDate;
        hebrewLocale.hebrewMonthNames.forEach(function (item) {
            newDate = `${object[0]} ${object[1]} ${object[2]}`;
            return newDate;
        });
        return newDate;
    }

    getHebrewDateInHebrew(date) {
        let newDate;
        if (!date) {
            return date;
        } else {
            let splittedDate = date.split(' ');
            if (this.IsLeapYear(splittedDate[2])) {
                hebrewLocale.monthNamesInHebrewLeap.forEach(function (item) {
                    newDate = `${splittedDate[0]} ${item[splittedDate[1]]} ${splittedDate[2]}`;
                    return newDate;
                });
            } else {
                hebrewLocale.monthNamesInHebrew.forEach(function (item) {
                    newDate = `${splittedDate[0]} ${item[splittedDate[1]]} ${splittedDate[2]}`;
                    return newDate;
                });
            }
            return newDate;
        }
    }

    IsLeapYear(year) {
        let yearsCycle = [3, 6, 8, 11, 14, 17, 19]
        let rem = year % 19;
        if (yearsCycle.indexOf(rem) != -1) {
            return true;
        } else {
            return false;
        }
    }

    hebrewYearIsLeapYear(date) {
        let splittedDate = date.split(' ');
        if ((parseInt(splittedDate[2]) % 100 === 0) ? (parseInt(splittedDate[2]) % 400 === 0) : (parseInt(splittedDate[2]) % 4 === 0)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get moment date.
     * @param date - string.
     */
    getMomentDate(date) {
        if (!date) {
            return date;
        }
        // return moment(date.split('+')[0]).format('DD-MMM-YYYY hh:mm a');
        return moment(date.split('+')[0]).format('MM/DD/YYYY');
    }

    formatDate(date) {
        let dateTemp = new Date(date);
        let day = dateTemp.getDate();
        if (day < 10) {
            day = day;
        }
        let month = dateTemp.getMonth() + 1;
        if (month < 10) {
            month = month;
        }
        let year = dateTemp.getFullYear();
        return { year: year, month: month, day: day };
    }

    /** Copy to  Clipboard*/
    copyToClipBoard(val) {
        console.log(val);
        if (val) {
            let selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = val;
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
        }
    }

    removeUnwantedKey(data) {
        const returnObj = {};
        if (!data && !this.isObject(data)) {
            return data;
        }
        Object.keys(data).forEach(key => {
            if (data[key] !== 'null' && data[key] !== '' && data[key] !== 'undefined' &&
                data[key] !== undefined && data[key] !== null && data[key] !== ' ') {
                returnObj[key] = data[key];
            }
        });
        return returnObj;
    }

    isObject(val) {
        return val instanceof Object;
    }

    getRand() {
        return Math.floor(Math.random() * 100000);
    }

    preventNumberInput(event: any) {
        var a = [];
        var k = event.which;

        for (let i = 48; i < 58; i++)
            a.push(i);

        if ((a.indexOf(k) >= 0))
            event.preventDefault();
    }

    preventSpecialCharacter(event: any) {
        var a = [];
        var k = event.charCode;

        if ((k >= 33 && k <= 91) || k == 32 || k == 64 || (k >= 123 && k <= 126) || (k >= 92 && k <= 96))
            event.preventDefault();
    }
}

