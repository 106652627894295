// Anngular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//NGX TOAST
import { ToastrModule } from 'ngx-toastr';

// Components
import { ConfirmationAlertComponent } from './pages/confirmation-alert/confirmation-alert.component';
import { SubheaderComponent } from './pages/subheader/subheader.component';
import { ToastNotificationComponent } from './pages/toast-notification/toast-notification.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ConfirmationAlertComponent,
    SubheaderComponent,
    ToastNotificationComponent
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot(),
  ],
  exports: [
    ConfirmationAlertComponent,
    SubheaderComponent,
    ToastNotificationComponent,
    ToastrModule
  ],
  entryComponents: [ConfirmationAlertComponent]
})
export class CoreModule { }
