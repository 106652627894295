// Angular
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// Constant
import { BUTTON_TYPE } from '../../../../state/core/constants/subheader.constant';

@Component({
  selector: 'kt-subheader-sog',
  templateUrl: './subheader.component.html',
})
export class SubheaderComponent implements OnInit {

  // Input
  @Input() title: string = '';
  @Input() desc: string = '';
  @Input() isAddButtonVisible: boolean = false;
  @Input() addButtonText: string = '';
  @Input() buttons: any = [];

  //Output
  @Output() buttonClickNotify = new EventEmitter();

  // Public properties
  buttonType = BUTTON_TYPE;

  /**
   * Component constructor
   */
  constructor() { }

  /**
   * On init
   */
  ngOnInit(): void { }

}
