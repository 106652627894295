import { initialState, PeopleListState, peopleListAdapter } from './state';
import { Actions, ActionTypes } from './actions';

export function reducer(state = initialState, action: Actions): PeopleListState {
    switch (action.type) {
        case ActionTypes.LOAD_ALL_PEOPLE: {
            return {
                ...state,
                isLoading: true,
                error: null,
                peoples: null,
            };
        }
        case ActionTypes.LOAD_ALL_PEOPLE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                peoples: action.data,
            };
        }
        case ActionTypes.ADD_PERSON: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case ActionTypes.ADD_PERSON_SUCCESS: {
            return {
                ...state,
                data: action,
                isLoading: false,
                error: null,
            };
        }
        case ActionTypes.GET_PERSON_BY_ID: {
            return {
                ...state,
                isLoading: true,
                error: null,
                data: action
            };
        }
        case ActionTypes.GET_PERSON_BY_ID_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.person
            };
        }
        case ActionTypes.UPDATE_PERSON: {
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }
        case ActionTypes.UPDATE_PERSON_SUCCESS: {
            return {
                ...state,
                data: action,
                isLoading: false,
                error: null,
            };
        }
        case ActionTypes.DELETE_PERSON: {
            return {
                ...state,
                isLoading: true,
                error: null,
                peoples: null,
            };
        }
        case ActionTypes.DELETE_PERSON_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                data: action.person
            };
        }
        case ActionTypes.ADD_CUSTOM_FIELD: {
            return {
                ...state,
                isLoading: true,
                error: null,
                fields: null,
            };
        }
        case ActionTypes.ADD_CUSTOM_FIELD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                fields: action.data,
            };
        }
        case ActionTypes.ADD_NEW_NOTE: {
            return {
                ...state,
                isLoading: true,
                error: null,
                notes: null,
            };
        }
        case ActionTypes.ADD_NEW_NOTE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                notes: action.data,
            };
        }
        case ActionTypes.UPDATE_NOTE: {
            return {
                ...state,
                isLoading: true,
                error: null,
                notes: null,
            };
        }
        case ActionTypes.UPDATE_NOTE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                notes: action.data,
            };
        }
        case ActionTypes.DELETE_NOTE: {
            return {
                ...state,
                isLoading: true,
                error: null,
                notes: null,
            };
        }
        case ActionTypes.DELETE_NOTE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                error: null,
                notes: action.note
            };
        }
        default:
            return state;
    }
}
